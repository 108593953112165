import { Loading } from '@/components/loading';
import { Lang } from '@/core/i18n/src/page-lang';
import dynamic from 'next/dynamic';
import css from 'styled-jsx/css';

const RegisterPage = dynamic(() => import('@/components/layouts/register'), {
  ssr: false,
  loading: () => (
    <div className='loading-wrapper'>
      <Loading.view />
      <style jsx>{styles}</style>
    </div>
  ),
});

function Register() {
  return <RegisterPage />;
}
export default Lang.SeoHead(Register);
export const getStaticPaths = Lang.getStaticPaths;
export const getStaticProps = Lang.getStaticProps({ key: 'register' });
const styles = css`
  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
